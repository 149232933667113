import "./Base.css";
import Home from "./Home";
import Education from "./pages/education/Education";
import Leadership from "./pages/leadership/Leadership";
import Projects from "./pages/projects/Projects";
import Research from "./pages/research/Research";

import WaveSVG from "../assets/bottom_wave.svg";

import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";

function Base() {
  return (
    <Router>
      <div className="App">
        <div className="flex flex-col main-container h-dvh">
          <div>
            <Link to="/">
              <p className="title pt-4 ">interactive resume - with dvh</p>
            </Link>
          </div>
          <div className="flex-1 flex items-center justify-center z-30">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/education" element={<Education />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/leadership" element={<Leadership />} />
              <Route path="/research" element={<Research />} />
            </Routes>
          </div>
          <div className="wave-container">
            <img className="wave" src={WaveSVG} alt="bottom-wave" />
          </div>
          <div className="pt-16"></div>
          <div id="footer">
            <p>(c) sherry weng '24</p>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default Base;
