import React from "react";
import { Container } from "react-grid-system";
// import CardGrid from "../../reusables/CardGrid";
import Search from "../../reusables/Search";
// figure out best import method for pictures, maybe load them all into an array in .js file and reference indexes below
// import Boc from "../assets/boc.webp";
// import Ghost from "../assets/ghost.webp";
// import Reaper from "../assets/reaper.webp";
// import Starfish from "../assets/starfish.webp";

function Projects() {
  const [search_string, setSearch] = React.useState("");

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  let clickHandler = (e) => {
    setSearch("");
  };

  //test array of mocked Projects pictures
  // const cards = [
  //   { props: { title: "BOC x GKB", description: "peppers", img: Boc } },
  //   {
  //     props: {
  //       title: "Brazilian Starfish",
  //       description: "peppers",
  //       img: Starfish,
  //     },
  //   },
  //   { props: { title: "Thai Dragon", description: "peppers", img: Reaper } },
  //   {
  //     props: {
  //       title: "Super Bhut Jholokia XW",
  //       description: "peppers",
  //       img: Ghost,
  //     },
  //   },
  //   {
  //     props: {
  //       title: "Super Bhut Jholokia JW",
  //       description: "peppers",
  //       img: Ghost,
  //     },
  //   },
  //   {
  //     props: { title: "Carolina Reaper", description: "peppers", img: Reaper },
  //   },
  //   { props: { title: "Scotch Brains", description: "peppers", img: Boc } },
  // ];

  return (
    <div className="">
      <p className="">Projects</p>
      <div id="search">
        <Search
          value={search_string}
          onClick={clickHandler}
          onChange={inputHandler}
        />
      </div>
      <div>
        {/* tbd: cleanup and remove spacers, cleanup css to pad correctly instead */}
        <div className="spacer"></div>
        <div className="spacer"></div>
        <Container id="content-wrap">
          <div className="spacer"></div>
          {/* <CardGrid cardsProp={cards} searchProp={search_string} /> */}
        </Container>
      </div>
    </div>
  );
}

export default Projects;
