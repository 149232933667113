import "./Home.css";
import SquareSVG from "../assets/square.svg";
import TriangleSVG from "../assets/triangle.svg";
import RhombusSVG from "../assets/rhombus.svg";
import CircleSVG from "../assets/circle.svg";

import { Link } from "react-router-dom";

function Home() {
  return (
    // <div className="flex justify-center items-center">
    <div className="grid grid-cols-2 md:grid-cols-4 gap-y-8 gap-4 lg:gap-12">
      <Link className="choice" to="/education">
        <img className="w-3/4 text-center" src={TriangleSVG} alt="testy" />
        <p className="choice-label">education</p>
      </Link>
      <Link className="choice" to="/projects">
        <img className="w-3/4 text-center" src={SquareSVG} alt="testy" />
        <p className="choice-label">projects</p>
      </Link>
      <Link className="choice" to="/research">
        <img className="w-3/4 text-center" src={RhombusSVG} alt="testy" />
        <p className="choice-label">research</p>
      </Link>
      <Link className="choice" to="/leadership">
        <img className="w-3/4 text-center" src={CircleSVG} alt="testy" />
        <p className="choice-label">leadership</p>
      </Link>
    </div>
    // </div>
  );
}

export default Home;
